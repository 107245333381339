import Dashboard from '../containers/Dashboard';
import Inventory from '../containers/Inventory';
import InventoryHistory from '../containers/InventoryHistory';
import LockDetails from '../containers/LockDetails';
import Properties from '../containers/Properties';
import PropertyDetails from '../containers/PropertyDetails';
import ProfitCenter from '../containers/ProfitCenter';
import Insights from '../containers/Insights';
// import WorkBench from '../containers/WorkBench';
import Customers from '../containers/Customers';
import CustomerDetails from '../containers/CustomerDetails';
import Visits from '../containers/Visits';
import Offers from '../containers/Offers';
import OfferDetails from '../containers/OfferDetails';
import Account from '../containers/Account';
import Integrations from '../containers/Integrations';
import Agents from '../containers/Agents';
import AgentForm from '../containers/AgentForm';
import InvoiceDetails from '../containers/InvoiceDetails';
import Communities from '../containers/Communities';
import Teams from '../containers/Teams';
import TeamForm from '../components/TeamForm';
import CommunityDetails from '../containers/CommunityDetails';
import CommunityAddForm from '../components/CommunityAddForm';
import ScheduledVisits from '../containers/ScheduledVisit';
import ScheduledVisitDetails from '../containers/ScheduledVisitDetails';
import InputProperty from '../components/PropertyInput';
import BuilderSettingsForm from '../components/BuilderSettingsForm';
import BuilderContentSettingsForm from '../components/BuilderContentSettingsForm';
import AfterTourHours from '../containers/AfterTourHours';
import Webhooks from '../containers/Webhooks';
import WebhookForm from '../components/WebhookForm';
import Reports from '../containers/Reports';
import ReportVisit from '../containers/ReportVisit';
import Training from '../containers/Training';
import SurveyQuestions from '../containers/SurveyQuestions';
import ReportPropertyVisit from '../containers/ReportPropertyVisit';
import Disclosures from '../components/Disclosures';

const routes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/training', name: 'Training', component: Training },
  { path: '/account/billing/invoices/:id', name: 'Account', component: InvoiceDetails },
  { path: '/account/billing/paid/:id', name: 'Account', component: InvoiceDetails },
  { path: '/account/billing/past-due/:id', name: 'Account', component: InvoiceDetails },
  // commented out, bsc we don't want to show this yet
  { path: '/inventory/:lockSerial', name: 'LockDetails', component: LockDetails },
  { path: '/inventory', name: 'Inventory', component: Inventory, roles: ['USER', 'PM'] },
  { path: '/inventory-history', name: 'Inventory History', component: InventoryHistory, roles: ['USER', 'PM'] },
  { path: '/afterTourHours', name: 'After Tour Hours', component: AfterTourHours, roles: ['USER', 'PM'] },
  { path: '/visits', name: 'Visits', component: Visits, roles: ['USER', 'PM'] },
  { path: '/properties/add', name: 'PropertyInput', component: InputProperty, roles: ['USER', 'PM'] },
  { path: '/properties/:propertyId', name: 'PropertyDetails', component: PropertyDetails, roles: ['USER', 'PM'] },
  { path: '/properties', name: 'Properties', component: Properties, roles: ['USER', 'PM'] },
  { path: '/reportPropertyTours', name: 'Report Property Tours', component: ReportPropertyVisit },
  { path: '/reports', name: 'Reports', component: Reports, roles: ['USER', 'PM'] },
  { path: '/reportVisit', name: 'Report Visit', component: ReportVisit },
  { path: '/profit-center', name: 'Profit Center', component: ProfitCenter },
  { path: '/integrations', name: 'Integrations', component: Integrations },
  { path: '/customers/:customerId', name: 'CustomerDetails', component: CustomerDetails, roles: ['USER', 'PM'] },
  { path: '/customers', name: 'Customers', component: Customers, roles: ['USER', 'PM'] },
  { path: '/insights', name: 'Insights', component: Insights },
  // { path: '/workbench', name: 'WorkBench', component: WorkBench },
  { path: '/account', name: 'Account', component: Account },
  { path: '/agents/add', name: 'AgentForm', component: AgentForm },
  { path: '/agents/:username', name: 'Agent details', component: AgentForm },
  {
    path: '/agents', name: 'Agents', component: Agents, roles: ['PM'],
  },
  { path: '/communities/add', name: 'CommunityAdd', component: CommunityAddForm, roles: ['USER', 'PM'] },
  { path: '/communities/:communityId', name: 'CommunityDetails', component: CommunityDetails, roles: ['USER', 'PM'] },
  { path: '/communities', name: 'Communities', component: Communities, roles: ['USER', 'PM'] },
  { path: '/teams/add', name: 'TeamForm', component: TeamForm },
  { path: '/teams/:teamId', name: 'TeamForm', component: TeamForm },
  { path: '/teams', name: 'Teams', component: Teams, roles: ['USER', 'PM'] },
  { path: '/schedule-visit/:scheduledVisitId', name: 'ScheduledVisitDetails', component: ScheduledVisitDetails, roles: ['USER', 'PM'] },
  { path: '/schedule-visit', name: 'ScheduledVisits', component: ScheduledVisits, roles: ['USER', 'PM'] },
  { path: '/offers/:offerId', name: 'OfferDetails', component: OfferDetails, roles: ['USER', 'PM'] },
  { path: '/offers', name: 'Offers', component: Offers, roles: ['USER', 'PM'] },
  { path: '/settings', name: 'Settings', component: BuilderSettingsForm, roles: ['USER', 'PM'] },
  { path: '/content-settings', name: 'Content Settings', component: BuilderContentSettingsForm, roles: ['USER', 'PM'] },
  // Webhooks
  { path: '/webhooks/add', name: 'WebhookForm', component: WebhookForm, roles: ['USER', 'PM'] },
  { path: '/webhooks/:webhookId', name: 'WebhookForm', component: WebhookForm, roles: ['USER', 'PM'] },
  { path: '/webhooks', name: 'Webhooks', component: Webhooks, roles: ['USER', 'PM'] },
  { path: '/survey', name: 'SurveyQuestions', component: SurveyQuestions, roles: ['USER', 'PM'] },
  {
    path: '/disclosures', name: 'Disclosures', component: Disclosures, roles: ['USER', 'PM'],
  },
];

export default routes;
